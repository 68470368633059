import { render, staticRenderFns } from "./open-group-list.vue?vue&type=template&id=6790f6c9&scoped=true&"
import script from "./open-group-list.vue?vue&type=script&lang=js&"
export * from "./open-group-list.vue?vue&type=script&lang=js&"
import style0 from "./open-group-list.vue?vue&type=style&index=0&id=6790f6c9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6790f6c9",
  null
  
)

export default component.exports