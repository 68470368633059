<template>
  <!--1-->
  <div class="open_group_main">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="open_group_main">
        <div class="open_group_list_box">
          <div v-if="teamStatus!=3" class="open_group_list_top">
            <span>本团剩余：</span>
            <span class="open_time">{{ countDownHour }}</span>
            <span>时</span>
            <span class="open_time">{{ countDownMinute }}</span>
            <span>分</span>
            <span class="open_time">{{ countDownSecond }}</span>
            <span>秒</span>
          </div>
          <div class="open_user_img">
            <div class="open_user_image">
              <img :src="teamOne.headpic" alt="">
            </div>
            <div class="open_user_type">
              <img src="" alt="">
              <span>{{ teamOne.type == 1 ? '团长' : '团员' }}</span>
            </div>
          </div>
          <div class="open_name_center">
            {{ teamStatus == 0 ? '待开团' : (teamStatus == 1 ? '拼团中...' : (teamStatus == 2 ? '拼团成功' : teamStatus == 3 ? '拼团失败' : '')) }}
          </div>
          <div class="open_style_center">还差<span>{{ productInfo.needer - productInfo.joins }}</span>人，超值商品免费到手！</div>
          <div class="open_user_img_box">
            <div v-for="(item, index) in team" :key="index" class="open_user_img_list">
              <img :src="item.headpic" alt="">
            </div>
          </div>
          <!--          <div class="open_group_btn">邀请好友参团</div>-->
        </div>
        <!--2-->
        <div class="open_list_data">
          <div class="open_list_l">
            <img :src="productInfo.goodsImg" alt="">
            <div class="open_list_img_top">
              <div class="open_list_title">开团免费拿</div>
              <div class="open_list_title_price">¥0</div>
            </div>
            <div class="open_list_title_bot">
              {{ teamStatus == 0 ? '待开团' : (teamStatus == 1 ? '拼团中...' : (teamStatus == 2 ? '拼团成功' : teamStatus == 3 ? '拼团失败' : '')) }}
            </div>
          </div>
          <div class="open_list_r">
            <div class="open_list_goodsName">{{ productInfo.goodsName }}</div>
            <div class="open_list_good_title">{{ productInfo.goodsName }}</div>
            <div class="open_list_price">
              <span class="open_vip">¥{{ productInfo.teamPprice }}</span>
              <span class="open_vip_pt"> ¥{{ productInfo.teamPrice }}</span>
            </div>
          </div>
        </div>
        <!--3-->
        <div class="open_img_link">
          <img :src="ruleImg" alt="">
        </div>
        <!--4-->
        <div class="open_span" style="text-align: center">猜我喜欢 为你推荐</div>
        <div>
          <div class="open_list_all_box">
            <div
              v-for="(item,index) in dataList"
              :key="index"
              class="open_list_box_son"
              @click="btnLink(item)"
            >
              <div class="open_data_image">
                <img :src="item.goodsImg" alt="">
              </div>
              <div class="open_data_all">
                <div class="open_data_title">{{ item.actName }}</div>
                <div class="open_data_name">{{ item.goodsName }}</div>
                <div class="open_data_jg">
                  <span class="open_vip">¥{{ item.teamPrice }}</span>
                  <span class="open_vip_pt"> ¥{{ item.originPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mescroll>
  </div>

</template>

<script>
import { getSpellGroupDescribe } from '@/services/goods'
import { assembleData } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'OpenGroupList',
  components: {
    mescroll
  },
  data() {
    return {
      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      countDownHour: '00',
      countDownMinute: '00',
      countDownSecond: '00',
      countDown: '',
      teamOne: '',
      team: '', // 拼团 list 3-5
      productInfo: {
        goodsImg: '', // 商品img
        goodsName: '', // 商品name
        teamPrice: '',
        teamPprice: '',
        needer: ''
      },
      teamStatus: '', // 0,1,2,3 状态
      ruleImg: '', // 中间的img
      dataList: '', // 推荐list
      teama: ''
    }
  },
  mounted() {
    this.myGroupList()
    this.gitAssemble()
  },
  methods: {
    btnLink: function(item) {
      const obj = {
        product_type: 1, // 暂时借口没有返回 这个字段 product_type
        goodId: item.goodsId,
        skuId: item.skuId
      }
      this.$store.dispatch('godetail', obj)
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.dataList = []
          // 把请求到的数据添加到列表
          this.dataList = this.dataList.concat(arr)

          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        page: pageNum,
        pageSize: pageSize,
        channel: 7
      }
      assembleData(data).then(res => {
        successCallback(res.data.item)
      })
    },
    // 我的拼团 列表
    myGroupList: function() {
      const data = {
        uid: this.$route.query.uid,
        ordersn: this.$route.query.ordersn,
        type: this.$route.query.type
      }
      getSpellGroupDescribe(data).then(res => {
        if (Number(res.code) === 200) {
          // this.countDown = this.startTimer(res.data.countDown)
          this.productInfo.goodsImg = res.data.productInfo.goods_img// 商品图片
          this.productInfo.goodsName = res.data.productInfo.goods_name// 商品name
          this.productInfo.teamPrice = res.data.productInfo.origin_price//
          this.productInfo.teamPprice = res.data.productInfo.team_price // needer
          this.productInfo.needer = res.data.productInfo.needer//
          this.productInfo.joins = res.data.productInfo.joins//
          this.ruleImg = res.data.ruleImg
          this.teamStatus = res.data.teamStatus
          this.team = res.data.team
          // 过滤 type ==1 为 团长的字段
          const newdata = res.data.team.filter(function(data) {
            // eslint-disable-next-line eqeqeq
            if (data.type == 1) {
              return data
            }
          })
          this.teamOne = newdata[0]
        }
      })
    },
    // list
    gitAssemble: function() {
      const data = {
        page: 1,
        channel: 7 // 渠道默认传7
      }
      assembleData(data).then(res => {
        if (Number(res.code) === 200) {
          this.dataList = res.data.item
        }
      })
    },
    startTimer(totalSecond) {
      // 倒计时
      // eslint-disable-next-line no-redeclare
      var totalSecond = totalSecond
      var interval = setInterval(
        function() {
          // 秒数
          var second = totalSecond
          // 小时位
          var hr = Math.floor(second / 3600)
          var hrStr = hr.toString()
          // eslint-disable-next-line eqeqeq
          if (hrStr.length == 1) hrStr = '0' + hrStr
          // 分钟位
          var min = Math.floor((second - hr * 3600) / 60)
          var minStr = min.toString()
          // eslint-disable-next-line eqeqeq
          if (minStr.length == 1) minStr = '0' + minStr
          // 秒位
          var sec = second - hr * 3600 - min * 60
          var secStr = sec.toString()
          // eslint-disable-next-line eqeqeq
          if (secStr.length == 1) secStr = '0' + secStr
          this.countDownHour = hrStr
          this.countDownMinute = minStr
          this.countDownSecond = secStr
          totalSecond--
          if (totalSecond < 0) {
            clearInterval(interval)
            this.countDownHour = '00'
            this.countDownMinute = '00'
            this.countDownSecond = '00'
          }
        }.bind(this),
        1000
      )
    }
  }

}
</script>

<style lang="less" scoped>
.open_group_main {
  background: #f6f6f6;

  .open_group_list_box {
    background: #fff;
    width: 100%;
    padding-bottom: 6px;
    box-sizing: border-box;

    .open_group_list_top {
      width: 100%;
      height: 42px;
      background: #e33f44;
      color: #fff;

      .open_time {
        background: #fff;
        line-height: 42px;
        border-radius: 4px;
        padding: 2px;
        color: #333;
      }

      span {
        margin: 2px;
      }
    }

    .open_user_img {
      width: 42px;
      height: 42px;
      margin: 0px auto;
      padding-top: 12px;
      box-sizing: border-box;
      position: relative;

      .open_user_image {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .open_user_type {
        font-size: 10px;
        width: 60px;
        padding: 3px 6px;
        box-sizing: border-box;
        background: #e33f44;
        border-radius: 12px;
        color: #fff;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .open_name_center {
      text-align: center;
      color: #e33f44;
      margin-top: 18px;
    }

    .open_style_center {
      text-align: center;
      margin: 6px 0 12px 0;

      span {
        color: #e33f44;
      }
    }

    .open_user_img_box {
      width: 100%;

      .open_user_img_list {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin: 4px;
        display: inline-block;
        overflow: hidden;

        img {
          width: 100%;
        }
      }
    }

    .open_group_btn {
      width: 200px;
      height: 42px;
      background: #e33f44;
      margin: 24px auto;
      border-radius: 24px;
      line-height: 42px;
      color: #fff;
    }
  }

  .open_list_data {
    width: 100%;
    height: 140px;
    background: #fff;
    margin: 12px auto;
    padding: 10px 0;
    box-sizing: border-box;
    overflow: hidden;
    text-align: left;

    .open_list_l {
      float: left;
      width: 120px;
      height: 120px;
      position: relative;

      img {
        width: 100%;
      }

      .open_list_img_top {
        position: absolute;
        left: 0;
        top: -12px;
        width: 60px;
        height: 50px;
        background-image: linear-gradient(#999065, #f7161e);
        border-radius: 0 0 50% 50%;

        .open_list_title {
          text-align: center;
          font-size: 10px;
          color: #fff;
          margin-top: 7px;
        }

        .open_list_title_price {
          font-size: 18px;
          color: #fff;
          margin-top: 6px;
          text-align: center;
        }
      }

      .open_list_title_bot {
        width: 100%;
        position: absolute;
        left: 0;
        background: #ff9900;
        color: #fff;
        padding: 2px;
        box-sizing: border-box;
        text-align: center;
        bottom: 0px;
      }
    }

    .open_list_r {
      width: 250px;
      float: left;
      padding: 10px;
      box-sizing: border-box;

      .open_list_goodsName {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
      }

      .open_list_good_title {
        width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 10px;
        color: #999;
        margin-top: 10px;
      }

      .open_list_price {
        margin-top: 30px;

        .open_vip {
          font-size: 16px;
          color: #e33f44;
        }

        .open_vip_pt {
          font-size: 12px;
          text-decoration: line-through;
        }
      }
    }
  }

  .open_img_link {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    margin: 12px auto;
  }

  .open_span {
    text-align: left;
    margin-left: 12px;
  }

  .open_list_all_box {
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    box-sizing: border-box;

    .open_list_box_son {
      position: relative;
      width: 169px;
      height: 305px;
      background: #fff;
      margin-right: 12px;
      float: left;
      margin-bottom: 6px;
      margin-top: 6px;
      border-radius: 6px;
      overflow: hidden;

      .open_data_image {
        width: 169px;
        height: 169px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .open_data_all {
        padding: 5px;
        box-sizing: border-box;

        .open_data_title {
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 18px;
        }

        .open_data_name {
          margin-top: 5px;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 18px;
          color: #999;
          font-size: 10px;
        }

        .open_data_jg {
          text-align: left;
          position: absolute;
          bottom: 12px;

          .open_vip {
            font-size: 16px;
            color: #e33f44;
          }

          .open_vip_pt {
            font-size: 12px;
            text-decoration: line-through;
          }
        }
      }
    }

    .open_list_box_son:nth-child(2n) {
      margin-right: 0px;
    }
  }
}

</style>
